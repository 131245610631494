<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 ml-5 mt-5">
      <h1>
        Ubicación de
        <span class="font-weight-light">{{
          selectedCorporative.shortName
        }}</span>
      </h1>
    </v-row>

    <!-- <div class="main-container">
      <v-container fluid class="fill-height pr-5 screen-width"> -->

    <v-row
      v-if="!loading"
      style="margin-left: 100px; margin-right: 100px"
      class="mt-3"
    >
      <v-col md="12" cols="12" sm="12">
        <l-map
          @update:center="centerUpdated"
          class="fill-height"
          style="height: 50vh"
          :zoom="zoom"
          :center="center"
        >
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker
            :lat-lng="[center[0], center[1]]"
            @update:lat-lng="positionUpdated"
            :draggable="true"
            :icon="getImage"
          >
            <l-popup> {{ business.zone }}! </l-popup>

            <l-tooltip>
              Mueva el marcador a la ubicación del comercio
            </l-tooltip>
          </l-marker>
          <v-geosearch :options="geosearchOptions"></v-geosearch>
        </l-map>
      </v-col>
      <v-col cols="12" md="6">
        <h2>Latitud</h2>
        <input
          type="number"
          class="control-input control-input-number"
          placeholder="Latitud"
          v-model="center[0]"
        />
      </v-col>

      <v-col cols="12" md="6">
        <h2>Longitud</h2>
        <input
          type="number"
          class="control-input control-input-number"
          placeholder="Longitud"
          v-model="center[1]"
        />
      </v-col>

      <v-col cols="12">
        <h2>Dirección</h2>
        <textarea
          v-model="business.addressDescription"
          placeholder="Dirección exacta del negocio"
          class="control-input"
          rows="3"
        ></textarea>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 ml-10 mr-10">
      <v-col cols="12">
        <!-- <v-divider></v-divider> -->
        <v-row justify="end">
          <v-btn
            class="save-btn"
            :loading="saving"
            @click="save"
            color="primary"
            dark
          >
            Guardar
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <!-- </v-container>
    </div> -->
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
// import Lottie from "@/components/Lottie";
// import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from "vue2-leaflet";

import "leaflet/dist/leaflet.css";
// import mapSkeleton from "./skeleton/map-skeleton";
// import axios from "axios";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
// import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "owner-map",
  // mixins: [lottieSettings, ledgerMixin],
  components: {
    // lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    // mapSkeleton,
    LTooltip,
    VGeosearch,
  },
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar",
      },
      business: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      center: [47.41322, -1.219482],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      snackbar: false,
      snackbarText: "",
      loading: true,
      saving: false,
      // save: false,
      prevValue: {},
      roles: ["sudo", "admin", "AC", "EFCM"],
    };
  },
  watch: {
    selectedCorporative() {
      this.business = null;
      this.getBusinessData();
    },
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 5;
        }, 10);
      }
    },
  },
  computed: {
    ...mapState(["selectedCorporative", "user"]),
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    // dynamicSize() {
    //   return [this.iconSize, this.iconSize * 1];
    // },
    // dynamicAnchor() {
    //   return [this.iconSize / 2, this.iconSize * 1];
    // },
    getImage() {
      return L.icon({
        iconUrl:
          this.business && this.business.logo && this.business.logo.original
            ? this.business.logo.original
            : require("@/assets/user.svg"),
        shadowUrl: require("@/assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },
  methods: {
    positionUpdated(e) {
      if (this.roles.includes(this.user.type)) {
        this.center = [e.lat, e.lng];
      }
    },
    centerUpdated(e) {
      if (this.roles.includes(this.user.type)) {
        this.center = [e.lat, e.lng];
      }
    },

    async save() {
      if (this.center[0] && this.center[1]) {
        this.saving = true;
        let data = {
          collection: "businessesCorporative",
          collectionId: this.selectedCorporative[".key"],
          latitude: this.center[0],
          longitude: this.center[1],
        };
        var httpGeneralUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpGeneralUpdateGeoPoint");
        httpGeneralUpdateGeoPoint(data)
          .then((result) => {
            // resolve("success");
            this.saving = false;
            this.snackbar = true;
            this.snackbarText = "Información actualizada exitosamente.";
          })
          .catch((err) => {
            this.loading = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
          });
        // db.collection("businessesCorporative")
        //   .doc(this.selectedCorporative[".key"])
        //   .update({
        //     addressDescription: this.business.addressDescription
        //       ? this.business.addressDescription
        //       : "",
        //     address: new fb.firestore.GeoPoint(this.center[0], this.center[1]),
        //   })
        //   .then(async () => {
        //     this.saving = false;
        //     this.snackbar = true;
        //     this.snackbarText = "Información actualizada exitosamente.";
        //   })
        //   .catch(() => {
        //     this.saving = false;

        //     this.snackbar = true;
        //     this.snackbarText =
        //       "Ocurrió un error inesperado, inténtelo nuevamente";
        //   });
      }
    },
    async getBusinessData() {
      if (!this.selectedCorporative) {
        this.$router.push({ path: "/" });
      }
      // this.business = Object.assign({}, this.selectedCorporative);

      await this.$binding(
        "business",
        db
          .collection("businessesCorporative")
          .doc(this.selectedCorporative[".key"])
      );

      this.center = [
        this.business.address.geopoint.latitude,
        this.business.address.geopoint.longitude,
      ];

      this.zoom = 1;
      this.loading = false;
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.getBusinessData();
    // this.prevValue = Object.assign({}, this.selectedBusiness);
  },
};
</script>

<style lang="scss">
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}
.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}
@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}
.save-btn {
  margin-top: 20px;
}
.vue2leaflet-map {
  z-index: 1;
}
</style>
